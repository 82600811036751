import React from 'react';

import AvatarAnim from "../video/win_rnd_recording_obr_v2.mp4";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

//import WebGLAvatar3D from '../components/WebGLAvatar3D/WebGLAvatar3D';
import Footer from '../components/Footer/Footer';


const Avatar3D = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Speaking 3D avatar</p>  
                </div>

                <div>
                    <video width="100%" controls autoPlay loop muted>
                        <source src={AvatarAnim}></source>
                    </video> 
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                            The initial project assumed the creation of a virtual assistant rendered on the server side (WebGL). 
                            During the project's development, we proposed a richer visualization rendered on the server side of the service.
                            The proposed solution would based on Unreal Engine and 3D Metahuman models. We implemented natural speech animation using 
                            AI models from NVIDIA Audio2Face.
                        </p>
                        <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                            The avatar gained realism and credibility thanks to the above changes, and the current project fully 
                            exploits the potential of the latest technologies.
                        </p>
                        <p style={{lineHeight: '1.4', textAlign: 'left'}}>
                            Additionally, we were responsabile for: <br />
                            <ul style={{color: `white`}}>
                                <li>Adaptation of the 3D avatar model to the application workflow.</li> 
                                <br />
                                <li>API development for the avatar model (Audio2Face communication &rarr; 3D model, and UE communication &harr; application).</li>
                            </ul>
                        </p>
                        <p>
                        

                            Client: <a href='https://www.cognitiva.dev/'>Cognitiva</a>

{/*                             For the <a href='https://www.cognitiva.dev/'>Cognitiva</a> company we <b>adapted the 3D avatar to WebGL</b> technology, 
                            and then <b>extended it to the animated model that imitates speaking</b> for a given waveform.<br/> */}
                        </p>
                    </div>
                </div>

{/*                 <div className="fullscreen">
                    < WebGLAvatar3D />
                </div>  */}
                
  {/*               <div className="infoframe">
                    <div className="infotext">
                        <p>
                            TOOLS: Three.js/WebGL, Blender, Faceit
                        </p>
                    </div>
                </div> */}

                {/* <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div> */}

                <Footer />
            </div>
        )
}

export default Avatar3D;