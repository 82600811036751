import React from 'react';
/*import {Link, animateScroll as scroll} from 'react-scroll';*/

import {Link} from 'react-router-dom';

import {ReactComponent as Logornd} from './../../images/logo_rndteam.svg';
// import work from './../../images/blacksquare_portfolio.png';
import plotoptix from './../../images/blacksquare_plotoptix.png';
import work from '../../images/blacksquare_work.png';
import about from './../../images/blacksquare_about.png';
import contact from './../../images/blacksquare_contact.png';

// import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';

import './Header.css';

class Header extends React.Component {

    render () {
        return (
         
            <div className="Header">

              <div className="topnav logo">
                <a href="/home"> <Logornd/> </a>
              </div>
              
              <div className="topnav plotoptix">        
                  <Link 
                    to="/plotoptix"
                  >
                    <img src={plotoptix} alt="menu: PlotOptiX"/>
                  </Link> 
              </div>
            
              <div className="topnav work">        
                  <Link 
                    to="/work"
                  >
                    <img src={work} alt="menu: work"/>
                  </Link> 
              </div>

              <div className="topnav about">
                <Link 
                  to="/about"
                >
                 <img src={about} alt="menu: about"/>
                </Link>
              </div>
              <div className="topnav contact">
              <Link 
                  to="/contact"
                >
                 <img src={contact} alt="menu: contact"/>
              </Link>
              </div>

              <div className="topnav rightm">
                <nav> 
                    <ul>
                      <Link to="/plotoptix">
                          <p>PlotOptiX</p>
                      </Link>
                      <Link to="/work">
                          <p>WORK</p>
                      </Link>  
                      <Link to="/about">
                          <p>ABOUT</p>
                      </Link>
                      <Link to="/contact">
                          <p>CONTACT</p>
                      </Link>
                    </ul>
                </nav>
              </div>
            </div>
        )
    }

}

export default Header;