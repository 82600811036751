import React from 'react';

import illu from "../images/illu/mockup.jpg";

import illuanim from "../video/illu.mp4";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const Illustration = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Illustration design</p>  
                </div>

                <div className="infoframe">
                    <video controls autoPlay muted>
                ``      <source src={illuanim}></source>
                    </video> 
                </div>

                <div className="infoframe">
                    <div className="infotext" style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <p>
                            <img src={illu} alt="illustration shown on a cotton bag"></img>
                        </p>
                        <p>
                            Client: <a href='https://www.instagram.com/p/CXilSAvNZFS/' target='_blank'>catering company 4dogs</a> <br />
                            Slogan: Magdalena Wrona <br />
                            Font: Memorian
                        </p>
                    </div>
                </div>

                {/* <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div> */}

                <Footer />
            </div>
        )

    }

export default Illustration