import React from 'react';

import GoypaAnim from "../video/goypa.mp4";
import goypamockup from "../images/goypa/goypa_mocup.jpg";
import goypafont from "../images/goypa/goypa_font.jpg";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const Goypa = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Logo project for GOYPA</p>  
                </div>

                <div>
                    <video width="100%" controls autoPlay loop muted>
                        <source src={GoypaAnim}></source>
                    </video> 
                </div>

                <div className="infoframe">
                    <div className="infotext" style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <p>
                            The gallery sells young Polish art, primarly painting and sculpture. The logo design was to be used 
                            on the websites and prints.
                        </p>
                        <p>
                            The logo communicates the company's visual aspect, and its colors refer to the company's Polish roots.
                        </p>
                        <p>
                            The lightness and slimness of the typography allows for the suggestion that the logotype is a pedestal 
                            (display) for the work of art. The curvature at the bottom edge of the sign deliberately introduce 
                            attention-grabbing tension without depriving it of legibility.
                        </p>

                        <p>
                            <img src={goypamockup} alt="mockup with goypa logo"></img>
                        </p>
                        <p>
                            <img src={goypafont} alt="font used in goypa logo"></img>
                        </p>

                        <p>
                            Client: <a href='https://www.goypa.pl/'>Gallery of Polish Art</a>
                        </p>
                    </div>
                </div>


                {/* <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div> */}

                <Footer />
            </div>
        )

    }

export default Goypa;