import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

import HomeTemplate from './components/HomeTemplate/HomeTemplate';


//pages
import Home from './Home';
import Work from './Work';
import PortfolioDS from './PortfolioDS';
import PlotOptiX from './PlotOptiX';
import About from './About';
import Contact from './Contact';
import GenerativeContent from './projects/GenerativeContent';
import Visualization from './projects/InstaProject';
import Posters from './projects/PostersProject';
import Attractors from './projects/AtraktorProject';
import Moon from './projects/MoonProject';
import Noise from './projects/NoiseProject';
import Yacht from './projects/YachtProject';
import Wormance from './projects/WormanceProject';
import Radio from './projects/RadioProject';
import Pose from './projects/PoseProject';
import Elproma from './projects/ElpromaProject';
import Avatar3D from './projects/Avatar3D';
import Goypa from './projects/Goypa';
import ETICS from './projects/ETICS';
import Illustration from './projects/Illustration';
import Fourdogs from './projects/Fourdogs';
import ETICSwww from './projects/ETICSwww';



class App extends React.Component {

/*   state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  }; */


  render() {
/*     let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    } */

    return (
  
      <div className="container-main"> 

        <Routes>
          {/* <Route path="/" element={<Home />} >  */}
          <Route path="" element={<HomeTemplate />}> 
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/work" element={<Work />} />      
            <Route path="/portfoliods" element={<PortfolioDS />} />
            <Route path="/plotoptix" element={<PlotOptiX />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/project/generativecontent" element={<GenerativeContent/>} />
            <Route path="/project/datavisualization" element={<Visualization />} />
            <Route path="/project/attractors" element={<Attractors />} />
            <Route path="/project/posters" element={<Posters />} />
            <Route path="/project/moon" element={<Moon />} />
            <Route path="/project/noise" element={<Noise />} />
            <Route path="/project/yachts" element={<Yacht />} />
            <Route path="/project/wormances" element={<Wormance />} />
            <Route path="/project/radio" element={<Radio />} />
            <Route path="/project/pose" element={<Pose />} />
            <Route path="/project/elproma" element={<Elproma />} />
            <Route path="/project/avatar3d" element={<Avatar3D />} />
            <Route path='/project/goypa' element={<Goypa />}/>
            <Route path='/project/etics' element={<ETICS />}/>
            <Route path='/project/eticswww' element={<ETICSwww/>}/>
            <Route path='/project/illustration' element={<Illustration />}/>
            <Route path='/project/fourdogs' element={<Fourdogs />}/>
          </Route>
        </Routes>
   
      
      
        <div>
{/*           <Header drawerClickHandler={this.drawerToggleClickHandler} />
          <Headerpl drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer drawerClickHandler={this.drawerToggleClickHandler} show={this.state.sideDrawerOpen} />
          {backdrop}    */}
        </div>   
      

      </div>        
    );
  }
}

export default App;
