
const Portfolio = [
    {
      id: 1,
      path: "/project/avatar3D",
      image: "https://rnd.team/rndteamimg/avatar3D.jpg",
      name: "Cognitiva",
      www: "https://www.cognitiva.dev/",
      category: "designtech", 
      who: "ds",
      description: "Speaking 3D avatar for"
    },
    {
      id: 2,
      path: "/project/Goypa",
      image: "https://rnd.team/rndteamimg/logogoypa.jpg",
      name: "GOYPA",
      www: "http://www.goypa.pl/",
      category: "design",
      who: "ds",
      description: "Logo design for"
    },
    {
      id: 3,
      path: "/project/elproma",
      image: "https://rnd.team/rndteamimg/elproma_cover.jpg",
      name: "Elproma Electronics",
      www: "http://www.elproma.com.pl/",
      category: "design",
      who: "ds",
      description: "Posters for"
    },
    {
      id: 4,
      path: "/project/ETICS",
      image: "https://rnd.team/rndteamimg/konf_kafelka.jpg",
      name: "SSO",
      www: "https://konferencjaetics.com.pl/",
      category: "design",
      who: "ds",
      description: "ETICS Conference Identity project for"
    },
    {
      id: 5,
      path: "/project/radio",
      image: "https://rnd.team/rndteamimg/radio_cover.jpg",
      name: "University of Alcala",
      www: "https://www.uah.es/en/",
      category: "tech",
      who: "rs",
      description: "Field coverage studies for"
    },
    {
      id: 6,
      path: "/project/ETICSwww",
      image: "https://rnd.team/rndteamimg/kafel_wwwetics.jpg",
      name: "ETICS conferences",
      www: "https://konferencjaetics.com.pl/",
      category: "design",
      who: "ds",
      description: "Web design for"
    },
    {
      id: 7,
      path: "/project/pose",
      image: "https://rnd.team/rndteamimg/pose_cover.jpg",
      name: "CIŚ",
      www: "https://www.cis.gov.pl/",
      category: "tech",
      who: "rs",
      description: "Human pose reconstruction for"
    },
    {
      id: 8,
      path: "/project/Illustration",
      image: "https://rnd.team/rndteamimg/illu.jpg",
      name: "4dogs",
      www: "https://www.instagram.com/p/CXilSAvNZFS/",
      category: "design",
      who: "ds",
      description: "Illustration for catering company"
    },
    {
      id: 9,
      path: "/project/yachts",
      image: "https://rnd.team/rndteamimg/yis_cover.jpg",
      name: "FSS",
      www: "https://fss.cc/",
      category: "designtech",
      who: "ds",
      description: "Superyacht visualization for"
    },
    {
      id: 10,
      path: "/plotoptix",
      image: "https://rnd.team/rndteamimg/PlotOptix.jpg",
      name: "PlotOptiX",
      www: "https://github.com/rnd-team-dev/plotoptix",
      category: "tech",
      who: "rs",
      description: ""
    },
    {
      id: 11,
      path: "/project/noise",
      image: "https://rnd.team/rndteamimg/sculpture_cover.jpg",
      name: "",
      www: "",
      category: "personal",
      who: "rs",
      description: "Noise sculptures"
    },
    {
      id: 12,
      path: "/project/moon",
      image: "https://rnd.team/rndteamimg/moon.jpg",
      name: "",
      www: "",
      category: "personal",
      who: "rs",
      description: "Making of the Moon"
    },
    {
      id: 13,
      path: "/project/datavisualization",
      image: "https://rnd.team/rndteamimg/instaproject.jpg",
      name: "",
      www: "",
      category: "personal",
      who: "rs",
      description: "Scraping Instagram"
    },
    {
      id: 14,
      path: "/project/attractors",
      image: "https://rnd.team/rndteamimg/attractor.jpg",
      name: "",
      www: "",
      category: "personal",
      who: "rs",
      description: "Attractors"
    },
    {
      id: 15,
      path: "/project/wormances",
      image: "https://rnd.team/rndteamimg/wormance_cover.jpg",
      name: "",
      www: "",
      category: "personal",
      who: "ds",
      description: "Wormances - generative creatures"
    },
    {
      id: 16,
      path: "/project/generativecontent",
      image: "https://rnd.team/rndteamimg/simplex.jpg",
      name: "",
      www: "",
      category: "personal",
      who: "rs",
      description: "Generative Content"
    }
  ];
  
  export default Portfolio;