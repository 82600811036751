import './PlotOptiX.css';

import React from "react";
import GitHubButton from 'react-github-btn';

import StructuredData from "react-google-structured-data";

import WebGLBanner from './components/WebGLBanner/WebGLBanner';

import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';


import Footer from './components/Footer/Footer';

import { Link } from 'react-router-dom';

//images
import patron from './images/plotoptix/voting_patron.jpg';
import comm_lic from './images/plotoptix/vip_patron.jpg';
import custom_code from './images/plotoptix/custom_code.jpg';

import b_patreon from './images/buttons/button_patreon.png';
import cennik from './images/buttons/cennik.png';
import b_contact from './images/buttons/button_contact.png';


const PlotOptiX = () => {

        return (
         
         <div> 
            <StructuredData
                type='SoftwareApplication'
                data={{
                name: "PlotOptiX",
                operatingSystem: "Windows 10, Linux",
                }}
            />

            <ScrollToTopOnMount />
            <ScrollToTop smooth />
              

            <div className="beginning">
             <p className="title"> Python ray tracing engine specialized in data visualization <br />
             <span style={{float: 'left', fontSize: '22px'}}>Powered by NVIDIA <a href="https://developer.nvidia.com/optix" target="_blanc">OptiX</a> framework.</span>
             <span style={{float: 'right', fontSize: '1.1vw'}}> <GitHubButton href="https://github.com/rnd-team-dev/plotoptix" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star rnd-team-dev/plotoptix on GitHub">Star</GitHubButton></span> 
             </p>
            </div>

            {/* <div style={{clear: "both"}}></div>  */}
           
        
            <div className="fullscreen">
             < WebGLBanner />
            </div>   

              <div className="row-wrapper25">
                {/*pierwszy kafelek*/}
                <div className="kafelek" style={{ backgroundImage: `url(${patron})` }}>
                  <a href="https://patreon.com/rndteam" target="_blanc">
                    <div className="box2">

                      <p style={{color: `#F39200`}}>
                        Free for personal use
                      </p>

                      <ul style={{color: `white`}}>
                        <li>All features enabled.</li>
                        <li>Exclusive content for Patrons.</li>
                      </ul>

                    </div>

                    <div className="box3">
                      <p>
                        <img src={b_patreon} alt="patreon button" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%'}}></img>   
                      </p>
                    </div>

                    <div className="box4">

                    </div>

                  </a>
                </div>
                {/*koniec pierwszego kafelka*/}

                {/*drugi kafelek*/}
                <div className="kafelek" style={{ backgroundImage: `url(${comm_lic})` }}>
                  <a href="https://patreon.com/rndteam" target="_blanc">

                    <div className="box2">
                      <p style={{color: `#F39200`}}>Commercial license</p>

                      <ul style={{color: `white`}}>
                        <li>Commercial use allowed.</li>
                        <li>Patron benefits included.</li>
                      </ul>
                    </div>

                    <div className="box3"> 
                      {/* <p>
                        <img src={b_offer} alt="offer button" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%'}}></img> 
                      </p> */}
                      <p>
                        <img src={b_patreon} alt="patreon button" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%'}}></img>   
                      </p>
                    </div>

                    <div className="box4">
                      <p>
                        <img src={cennik} alt="offer button" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%'}}></img> 
                      </p>
                    </div>

                  </a>
                </div>
                {/*koniec drugiego kafelka*/}

                {/*trzeci kafelek*/}
                <div className="kafelek" style={{ backgroundImage: `url(${custom_code})` }}>
                  <Link to="/contact">

                    <div className="box2">                
                      <p style={{color: `#F39200`}}>Dedicated B2B ray-tracing solutions</p>
                      <p style={{color: `white`}}>Standard features do not cover your use case?</p>
                    </div>

                    <div className="box3">
                      <p>
                        <img src={b_contact} alt="contact button" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '70%'}}></img> 
                      </p>
                    </div>

                    <div className="box4">
                      <p style={{color: `white`}}>We write customized code.</p>
                        <ul className="pcell_ul">
                          <li>industrial and academic research</li>
                          <li>Python prototypes, C++/CUDA optimizations</li>
                          <li>materials, cameras, fx, ...</li>
                        </ul>
                    </div>

                  </Link>
                </div>
                {/*koniec trzeciego kafelka*/}

              <div className="kafelek pcolumn_text" style={{ transform: `none` }}>
                
                <p style={{color: `white`}}>
                  NEWS
                </p>

                <p>
                  <span style={{color: `#CCC`}}>(03.01.2025) v0.18.3 released:</span><br />
                  <span style={{color: `#999`}}>OIDN denoiser updated to 2.3.1 (faster, high-quality final frame).</span>
                </p>

                <p>
                  <span style={{color: `#CCC`}}>(14.12.2024) v0.18.2 released:</span><br />
                  <span style={{color: `#999`}}>Moved to OptiX 8.1 and ffmpeg 7.
                  Efficiency improvements.</span>
                </p>

                <p>
                  <span style={{color: `#CCC`}}>(10.03.2024) v0.18.1 released:</span><br />
                  <span style={{color: `#999`}}>Intel Open Image denoiser. Features supporting volume plots.</span>
                </p>

                <p>
                  <span style={{color: `#CCC`}}>(18.08.2023) v0.17.0 released:</span><br />
                  <span style={{color: `#999`}}>Noise-balanced sampling.</span>
                </p>

                <p>
                  <span style={{color: `#CCC`}}>(04.06.2023) v0.16.1 released:</span><br />
                  <span style={{color: `#999`}}>Moved to Optix 7.7. New beziers and ribbon geometries. Support
                  for CuPy arrays.</span>
                </p>

                <p>
                  <span style={{color: `#CCC`}}>(17.05.2023) v0.16.0 released:</span><br />
                  <span style={{color: `#999`}}>Fast and direct access to texture and geometry data on gpu,
                  support for PyTorch tensors.</span>
                </p>

              </div>

            </div>

            <div className="hline">
              <p>
                RESOURCES
              </p>
            </div>

            <div className="row-wrapper25" style={{ justifyContent: `center` }}>
              <div className="pbutton25">
                <p>
                  <a href="https://plotoptix.rnd.team" target="_blanc">
                    <span style={{color: "white"}}>DOCUMENTATION</span>             
                  </a>
                </p>
              </div>

              <div className="pbutton25">
                <p>
                  <a href="https://github.com/rnd-team-dev/plotoptix" target="_blanc">
                    <span style={{color: "white"}}>
                      GitHub SOURCES
                    </span>        
                  </a>
                </p>
              </div>

              <div className="pbutton25">
                <p>
                  <a href="https://plotoptix.rnd.team" target="_blanc">
                    <span style={{color: "white"}}>
                      PyPI RELEASES
                    </span>    
                  </a>
                </p>
              </div>
            </div>

          <Footer />
           
         </div>
      );
  }

export default PlotOptiX;