import React from 'react';

import etics1 from "../images/etics24/etics1.jpg";
import etics2 from "../images/etics24/etics2.jpg";
import etics3 from "../images/etics24/zestaw_gazdzetow.jpg";

import eticsanim from "../video/etics.mp4";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const ETICS = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Visual identification for ETICS conference</p>  
                </div>

                <div className="infoframe">
                    <video controls autoPlay loop muted>
                ``      <source src={eticsanim}></source>
                    </video> 
                </div>

                <div className="infoframe">
                    <div className="infotext" style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <p>
                            For several years, we have been responsible for the design of the key visual and
                            design/production of prints for annual ETICS conference on thermal insulation systems.
                            The designs aim to blend with the spirit of each conference location while communicating the conference
                            themes and maintaining the permanent motif - the SUN.
                        </p>
                        <p>
                            The 2024 conference edition took place in the large and original spaces of the Żnin Sugar Factory. 
                            In this year the SUN symbol was manipulated to refer to the entrance metal structure of the building
                            where the conference took place. We have decided on a calm visual identification that is a natural
                            complement to the conference area, key colors related equally to the sunlight, the client logo palette, 
                            and the brown sugar color.
                        </p>
                        <p>
                            We were responsible for the design and production of: <br />
                            <ul>
                                <li>sculpture of the conference logo for the stage,</li>
                                <li>signposts,</li>
                                <li>rollups,</li>
                                <li>winders,</li>
                                <li>advertising walls,</li>
                                <li>glass door stickers,</li>
                                <li>totems,</li>
                                <li>identifiers,</li> 
                                <li>notebooks,</li>
                                <li>cotton bag,</li>
                            </ul>
                            as well as animation displayed on screens on the location, graphics for social media, 
                            newsletters and electronic versions of programs.
                        </p>
                        <p>
                            <img src={etics1} alt="visuals for the conference ETICS"></img>
                        </p>
                        <p>
                            <img src={etics2} alt="visuals for the conference ETICS"></img>
                        </p>
                        <p>
                            <img src={etics3} alt="visuals for the conference ETICS"></img>
                        </p>
                        <p>
                            Moreover, our studio:
                            <ul>
                                <li>supports local printing houses,</li>
                                <li>makes sure that the printing is on certified papers.</li>
                            </ul>
                        </p>
                        <p>
                            Client: <a href='http://www.systemyocieplen.pl/' target='_blank'>Stowarzyszenie na Rzecz Systemów Ociepleń</a>
                        </p>
                    </div>
                </div>

                {/* <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div> */}

                <Footer />
            </div>
        )

    }

export default ETICS