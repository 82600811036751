import { Outlet } from "react-router-dom";
import Header from "../Header/Header";

const HomeTemplate = () => {

    return (
        <div>
            <Header />
            <Outlet />
        </div>
    );
}

export default HomeTemplate;