import React from 'react';

import etics1 from "../images/etics24/wwwETICS_mockup.jpg";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import Footer from '../components/Footer/Footer';

const ETICSwww = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Web design for ETICS conference</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext" style={{lineHeight: '1.4', textAlign: 'left'}}>
                        <p>
                            <img src={etics1} alt="web design for the conference ETICS"></img>
                        </p>
                        <p>
                            We have designed a well-thought-out graphic solution that facilitates 
                            navigation for various display resolutions.
                        </p>
                        <p>
                            The logic of the previously used pages was also simplified 
                            so the information is easily available in the clear structures.
                        </p>
                        <p>
                            Client: <a href='https://konferencjaetics.com.pl/en/home/' target='_blank'>Stowarzyszenie na Rzecz Systemów Ociepleń</a>
                        </p>
                    </div>
                </div>

                {/* <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div> */}

                <Footer />
            </div>
        )

    }

export default ETICSwww