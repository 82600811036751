import React from 'react';

import {Link} from 'react-router-dom';

import './Footer.css';

import Fb from '../../images/icons/f_logo_RGB-White_58.png';
import Be from '../../images/icons/behance.png';
import In from '../../images/icons/glyph-logo_May2016.png';

const Footer = () => {
        return (
                       
        <div>
            <div className="ending"></div>

                <div>
                    <footer className="footer">
                        <div className="ficons">
                            <p>                                         
                                <a href="https://www.facebook.com/pg/rndteam" target="_blank" rel="noopener noreferrer"><img src={Fb} alt="link to Facebook"></img></a>
                                <a href="https://www.instagram.com/rnd.team.studio" target="_blank" rel="noopener noreferrer"><img src={In} alt="link to Instagram"></img></a>
                                <a href="https://www.behance.net/rndteam" target="_blank" rel="noopener noreferrer"><img src={Be} alt="link to Behance"></img></a>
                            </p> 
                            <p>
                                <Link to="/contact">CONTACT US</Link>
                            </p>
                            <p>
                                <a href="https://rnd.team/privacy_policy.html" target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>
                                {/* <Link to="">PRIVACY POLICY</Link> */}
                            </p>
                            <p>
                                <span>&#169;</span> Copyright 2019-2025<br />
                                <a href="https://rnd.team" target="_blank" rel="noopener noreferrer">RnD Team Design Studio</a>
                            </p>

                        </div>
                    
                    </footer>
                </div>

        </div>
        )
}

export default Footer;