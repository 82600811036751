import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import wormance_01 from '../images/wormances/wormances_slides.jpg';
//import yacht_02 from '../images/yachts/yacht_2.jpg';
//import yacht_sys from '../images/yachts/yacht_systems.jpg';
//import yacht_03 from '../images/yachts/yacht_3.jpg';

import Footer from '../components/Footer/Footer';

import { useNavigate } from 'react-router-dom';

const WormancesProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning">
                    <p className="title">Wormances</p>
                </div>

                <div className="fullscreen">
                    <video autoPlay muted loop src="https://rnd.team/mp4/wormance.mp4" type="video/mp4"></video>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            Wormances is the generative art project prepared by Dorota for her diploma at Warsaw School of Art. Wormances
                            are creatures sensitive to the activity of artists who got selected by Behance curators.<br />
                            Visit the project page on <a href="https://www.behance.net/gallery/105638439/Wormances" target="_blanc">Behance</a>.
                        </p>

                        <p>
                            <img src={wormance_01} alt="Wormances all in black and white"></img><br/><br/>
                        </p>
                    </div>
                </div>

                {/* <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div> */}

                <Footer />

            </div>
        )
}

export default WormancesProject;